import React, { FC, useEffect, useMemo } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Avatar, Box, Button, Divider, Drawer, Hidden, Typography } from '@mui/material';
import BadgeIcon from '@mui/icons-material/BadgeOutlined';
import PersonIdIcon from '@mui/icons-material/AssignmentIndOutlined';
import ApprovalIcon from '@mui/icons-material/ApprovalOutlined';
import BusinessIcon from '@mui/icons-material/BusinessOutlined';
import GroupWorkIcon from '@mui/icons-material/GroupWorkOutlined';
import PeopleIcon from '@mui/icons-material/PeopleOutline';
import PhoneIcon from '@mui/icons-material/MobileFriendlyOutlined';
import FactCheckIcon from '@mui/icons-material/FactCheckOutlined';
import DomainVerifiedIcon from '@mui/icons-material/DomainVerificationOutlined';
import SettingIcon from '@mui/icons-material/SettingsOutlined';
import ReceiptIcon from '@mui/icons-material/ReceiptLongOutlined';
import SellIcon from '@mui/icons-material/SellOutlined';
import useAuth from 'hooks/useAuth';
import NavSection, { getItems, NavItemModel } from '../NavSection';
import Scrollbar from '../Scrollbar';
import { hasValue } from '../inputs/inputHelpers';
import ContactUsButton from './ContactUsButton';
import { appPermissions, localRoutes, remoteRoutes, rootAdminUserRoles } from '../../constants';
import { appSettings, remoteConfigData } from '../../config';
import VerifyLogo from '../../logos/VerifyLogo';
import { toTitleCase, trimString } from '../../utils/stringHelpers';
import CopyWrapper from '../CopyWrapper';


interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

type Section = {
  title: string,
  items: NavItemModel[]
};

const getSections = (
  enableTinVerify: boolean,
  enablePeopleTemplates: boolean,
  enableBusinessTemplates: boolean,
  enablePeopleBulkImports: boolean,
  enableCompanyBulkImports: boolean
): Section[] => {
  return [
    {
      title: 'Customers',
      items: [
        {
          title: 'People',
          icon: <PeopleIcon fontSize="small"/>,
          path: localRoutes.people,
          roles: [appPermissions.CUSTOMERS_VIEW],
          children: [
            {
              title: 'Profiles',
              path: localRoutes.people,
              roles: [appPermissions.CUSTOMERS_VIEW]
            },
            enablePeopleBulkImports && 
            {
              title: 'Bulk Imports',
              path: localRoutes.bulkPeopleProfileImports,
              roles: [appPermissions.CUSTOMERS_VIEW]
            },
            enablePeopleTemplates &&
            {
              title: 'Onboarding Templates',
              path: localRoutes.peopleTemplates,
              roles: [appPermissions.CUSTOMERS_VIEW]
            },
          ]
        },
        {
          title: 'Companies',
          icon: <BusinessIcon fontSize="small"/>,
          path: localRoutes.companies,
          roles: [appPermissions.CUSTOMERS_VIEW],
          children: [
            {
              title: 'Profiles',
              path: localRoutes.companies,
              roles: [appPermissions.CUSTOMERS_VIEW]
            },
            enableCompanyBulkImports && 
            {
              title: 'Bulk Imports',
              path: localRoutes.bulkCompanyProfileImports,
              roles: [appPermissions.CUSTOMERS_VIEW]
            },
            enableBusinessTemplates &&
            {
              title: 'Onboarding Templates',
              path: localRoutes.companyTemplates,
              roles: [appPermissions.CUSTOMERS_VIEW]
            },
          ]
        },
      ]
    },
    {
      title: 'Products',
      items: [
        {
          title: 'Consent',
          icon: <ApprovalIcon fontSize="small"/>,
          path: localRoutes.consentRequests,
          roles: [appPermissions.CONSENT_VIEW, appPermissions.CONSENT_TRIGGER, appPermissions.BULK_CONSENT_VIEW, appPermissions.BULK_CONSENT_TRIGGER],
          children: [
            {
              title: 'Single Requests',
              path: localRoutes.consentRequests,
              roles: [appPermissions.CONSENT_VIEW, appPermissions.CONSENT_TRIGGER],
            },
            {
              title: 'Bulk Requests',
              path: localRoutes.bulkConsentRequests,
              roles: [appPermissions.BULK_CONSENT_VIEW, appPermissions.BULK_CONSENT_TRIGGER],
            },
            {
              title: 'Templates',
              path: localRoutes.consentTemplates,
              roles: [appPermissions.CONSENT_TEMPLATE_VIEW, appPermissions.CONSENT_TEMPLATE_EDIT]
            },
            {
              title: 'Data Custodians',
              path: localRoutes.dataCustodians,
              roles: [appPermissions.CONSENT_DATA_CUSTODIAN_EDIT, appPermissions.CONSENT_DATA_CUSTODIAN_VIEW],
            },
          ]
        },
        {
          title: 'ID Verification',
          icon: <PersonIdIcon fontSize="small"/>,
          path: localRoutes.nationalIdVerifications,
          roles: [appPermissions.ID_VERIFICATION_VIEW, appPermissions.ID_VERIFICATION_TRIGGER, appPermissions.BULK_ID_VERIFICATION_VIEW, appPermissions.BULK_ID_VERIFICATION_TRIGGER],
          children: [
            {
              title: 'Single Requests',
              path: localRoutes.nationalIdVerifications,
              roles: [appPermissions.ID_VERIFICATION_VIEW, appPermissions.ID_VERIFICATION_TRIGGER, ],
            },
            {
              title: 'Bulk Requests',
              path: localRoutes.bulkNationalIdVerifications,
              roles: [appPermissions.BULK_ID_VERIFICATION_VIEW, appPermissions.BULK_ID_VERIFICATION_TRIGGER]
            },
          ]
        },
        {
          title: 'Phone Verification',
          icon: <PhoneIcon fontSize="small"/>,
          path: localRoutes.phoneNumberVerifications,
          roles: [appPermissions.PHONE_NUMBER_VERIFICATION_VIEW, appPermissions.PHONE_NUMBER_VERIFICATION_TRIGGER, appPermissions.BULK_PHONE_NUMBER_VERIFICATION_VIEW, appPermissions.BULK_PHONE_NUMBER_VERIFICATION_TRIGGER],
          children: [
            {
              title: 'Single Requests',
              path: localRoutes.phoneNumberVerifications,
              roles: [appPermissions.PHONE_NUMBER_VERIFICATION_VIEW, appPermissions.PHONE_NUMBER_VERIFICATION_TRIGGER, ]
            },
            {
              title: 'Bulk Request',
              path: localRoutes.bulkPhoneNumberVerifications,
              roles: [appPermissions.BULK_PHONE_NUMBER_VERIFICATION_VIEW, appPermissions.BULK_PHONE_NUMBER_VERIFICATION_TRIGGER]
            },
          ]
        },
        enableTinVerify &&
                {
                  title: 'TIN Verifications',
                  icon: <BadgeIcon fontSize="small"/>,
                  path: localRoutes.tinVerifications,
                  roles: [appPermissions.TIN_VERIFICATION_VIEW, appPermissions.TIN_VERIFICATION_TRIGGER, appPermissions.BULK_TIN_VERIFICATION_VIEW, appPermissions.BULK_TIN_VERIFICATION_TRIGGER],
                },

        {
          title: 'AML Screening',
          icon: <FactCheckIcon fontSize="small"/>,
          path: localRoutes.amlScreeningRequests,
          roles: [appPermissions.AML_SCREENING_VIEW, appPermissions.AML_SCREENING_TRIGGER, appPermissions.BULK_AML_SCREENING_VIEW, appPermissions.BULK_AML_SCREENING_TRIGGER],
          children: [
            {
              title: 'Single Requests',
              path: localRoutes.amlScreeningRequests,
              roles: [appPermissions.AML_SCREENING_VIEW, appPermissions.AML_SCREENING_TRIGGER]
            },
            {
              title: 'Bulk Requests',
              path: localRoutes.bulkAmlScreeningRequests,
              roles: [appPermissions.BULK_AML_SCREENING_VIEW, appPermissions.BULK_AML_SCREENING_TRIGGER]
            },
          ]
        },
        {
          title: 'Business Search',
          icon: <DomainVerifiedIcon fontSize="small"/>,
          path: localRoutes.businessSearches,
          roles: [appPermissions.BUSINESS_SEARCH_VIEW, appPermissions.BUSINESS_SEARCH_TRIGGER, appPermissions.BULK_BUSINESS_SEARCH_VIEW, appPermissions.BULK_BUSINESS_SEARCH_TRIGGER],
          children: [
            {
              title: 'Single Requests',
              path: localRoutes.businessSearches,
              roles: [appPermissions.BUSINESS_SEARCH_VIEW, appPermissions.BUSINESS_SEARCH_TRIGGER]
            },
            {
              title: 'Bulk Requests',
              path: localRoutes.bulkBusinessSearches,
              roles: [appPermissions.BULK_BUSINESS_SEARCH_TRIGGER]
            },
          ]
        },
      ]
    },
    {
      title: 'Management',
      items: [
        {
          title: 'Staff',
          icon: <PeopleIcon fontSize="small"/>,
          path: localRoutes.staff,
          roles: [appPermissions.PARTNER_MEMBERS_VIEW, appPermissions.PARTNER_MEMBERS_EDIT]
        },
        {
          title: 'Partners',
          icon: <GroupWorkIcon fontSize="small"/>,
          path: localRoutes.partners,
          roles: [appPermissions.PARTNERS_EDIT, appPermissions.PARTNERS_VIEW],
          requiresPrimaryUser: true
        },
        {
          title: 'Settings',
          icon: <SettingIcon fontSize="small"/>,
          path: localRoutes.settings,
          roles: [appPermissions.PARTNER_SETTINGS_VIEW, appPermissions.PARTNERS_EDIT]
        },
        {
          title: 'Invoices',
          icon: <ReceiptIcon fontSize="small"/>,
          path: localRoutes.invoices,
          roles: [appPermissions.VIEW_INVOICES, ...rootAdminUserRoles],
          requiresAllRoles: true,
          requiresPrimaryUser: true
        },
        {
          title: 'Price Bands',
          icon: <SellIcon fontSize="small"/>,
          path: localRoutes.priceBands,
          roles: [appPermissions.VIEW_PRICE_BANDS, ...rootAdminUserRoles],
          requiresAllRoles: true,
          requiresPrimaryUser: true
        }
      ]
    },
  ];
};

const DashboardSidebar: FC<React.PropsWithChildren<DashboardSidebarProps>> = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const enableTinVerify = remoteConfigData.enableTinVerification();
  const enablePeopleTemplates = remoteConfigData.enablePeopleTemplates();
  const enableCompanyTemplates = remoteConfigData.enableCompanyTemplates();
  const enablePeopleBulkImports = remoteConfigData.enablePeopleBulkImports();
  const enableCompanyBulkImports = remoteConfigData.enableCompanyBulkImports();

  const sections = getSections(
    enableTinVerify, 
    enablePeopleTemplates, 
    enableCompanyTemplates, 
    enablePeopleBulkImports, 
    enableCompanyBulkImports);

  const sectionsData = useMemo<Section[]>(() => sections
    .map((it) => ({ ...it, items: getItems(it.items, user) }))
    .filter((it) => hasValue(it.items)), [user, sections]);

  const content = (
        <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%'
            }}
        >
            <Scrollbar options={{ suppressScrollX: true }}>
                <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%'
                    }}
                >
                    <Hidden lgDown>
                        <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'left',
                              alignItems: 'center',
                              p: 2
                            }}
                        >
                            <RouterLink to="/">
                                <VerifyLogo
                                    sx={{
                                      width: 50,
                                      height: 50,
                                    }}
                                />
                            </RouterLink>
                            <Box
                                sx={{
                                  ml: 1
                                }}
                            >
                                <Typography
                                    color="text.primary"
                                    variant="h6"
                                >
                                    {appSettings.appName}
                                </Typography>
                            </Box>
                        </Box>
                    </Hidden>
                    <Box sx={{ px: 2, pb: 2 }}>
                        <Box
                            sx={{
                              alignItems: 'center',
                              backgroundColor: 'background.default',
                              borderRadius: 1,
                              display: 'flex',
                              overflow: 'auto',
                              p: 2
                            }}
                        >
                            <Avatar
                                src={user.avatar}
                                sx={{
                                  cursor: 'pointer',
                                  height: 36,
                                  width: 36
                                }}
                            />
                            <Box sx={{ ml: 2 }}>
                                <Typography
                                    color="textPrimary"
                                    variant="subtitle2"
                                >
                                    {toTitleCase(user.name)}
                                </Typography>
                                <Typography
                                    color="textSecondary"
                                    variant="body2"
                                >
                                    <CopyWrapper value={trimString(user.email, 20, '...')}/>
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Divider/>
                    <Box sx={{ p: 2 }}>
                        {sectionsData.map((section) => (
                            <NavSection
                                key={section.title}
                                pathname={location.pathname}
                                sx={{
                                  '& + &': {
                                    mt: 3
                                  }
                                }}
                                {...section}
                            />
                        ))}
                    </Box>
                    <Box sx={{
                      marginTop: 'auto',
                      width: '100%'
                    }}
                    >
                        <Divider/>
                        <Box
                            sx={{
                              px: 2,
                              py: 3
                            }}
                        >
                            <Button
                                color="primary"
                                fullWidth
                                sx={{
                                  mt: 2,
                                  justifyContent: 'flex-start',
                                  textTransform: 'none',
                                }}
                                href={remoteConfigData.feedbackSurveyUrl()}
                                target="_blank"
                                variant="text"
                            >
                                Give us feedback
                            </Button>
                            <ContactUsButton title="Contact us"/>
                            <Button
                                color="primary"
                                fullWidth
                                sx={{ mt: 2 }}
                                href={remoteRoutes.apiDocumentation}
                                target="_blank"
                                variant="contained"
                            >
                                API Documentation
                            </Button>

                        </Box>
                    </Box>
                </Box>
            </Scrollbar>
        </Box>
  );

  return (
        <>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    onClose={onMobileClose}
                    open={openMobile}
                    PaperProps={{
                      sx: {
                        backgroundColor: 'background.paper',
                        width: 280
                      }
                    }}
                    variant="temporary"
                >
                    {content}
                </Drawer>
            </Hidden>
            <Hidden lgDown>
                <Drawer
                    anchor="left"
                    open
                    PaperProps={{
                      sx: {
                        backgroundColor: 'background.paper',
                        height: '100%',
                        width: 280,
                        border: 'none'
                      }
                    }}
                    variant="persistent"
                >
                    {content}
                </Drawer>
            </Hidden>
        </>
  );
};

export default DashboardSidebar;
