import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { matchPath } from 'react-router-dom';
import type { ListProps } from '@mui/material';
import { List, ListSubheader } from '@mui/material';
import NavItem from './NavItem';
import { hasAllRoles, hasAnyRole, isRootUser, localRoutes } from '../constants';
import { hasValue } from './inputs/inputHelpers';
import { AppUser } from '../types/user';

export interface NavItemModel {
  path?: string;
  icon?: ReactNode;
  info?: ReactNode;
  children?: NavItemModel[];
  title: string;
  roles?: string[];
  requiresAllRoles?: boolean;
  requiresPrimaryUser?: boolean;
}

interface NavSectionProps extends ListProps {
  items: NavItemModel[];
  pathname: string;
  title: string;
}

const renderNavItems = ({
  depth = 0,
  items,
  pathname
}: {
  items: NavItemModel[];
  pathname: string;
  depth?: number;
}): JSX.Element => (
  <List disablePadding>
    {items.reduce(
      // eslint-disable-next-line @typescript-eslint/no-use-before-define, no-use-before-define
      (acc, item) => reduceChildRoutes({
        acc,
        item,
        pathname,
        depth
      }),
      []
    )}
  </List>
);

const reduceChildRoutes = ({
  acc,
  pathname,
  item,
  depth
}: {
  acc: JSX.Element[];
  pathname: string;
  item: NavItemModel;
  depth: number;
}): Array<JSX.Element> => {
  const key = `${item.title}-${depth}`;
  let exactMatch = item.path ? !!matchPath({
    path: item.path,
    end: false
  }, pathname) : false;

  if (pathname === '/' && item.path === localRoutes.people) {
    exactMatch = true;
  }

  // Hack to make sure the people menu is active when on the root path
  if (pathname === '/' && item.path === '/') {
    exactMatch = false;
  }

  if (item.children) {
    const partialMatch = item.path ? !!matchPath({
      path: item.path,
      end: true
    }, pathname) : false;

    acc.push(
      <NavItem
        active={partialMatch}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={partialMatch}
        path={item.path}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          items: item.children,
          pathname
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        active={exactMatch}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        path={item.path}
        title={item.title}
      />
    );
  }

  return acc;
};

export const getItems = (rawItems: NavItemModel[], user:AppUser) => {
  const items = rawItems.filter(hasValue);

  const canShowNavItem = (it: NavItemModel): boolean => {
    if (!Boolean(it))
      return false;
    if (!hasValue(it.roles)) return true;

    if (it.requiresPrimaryUser && !isRootUser(user)) return false;

    if (it.requiresAllRoles) return hasAllRoles(user, it.roles);

    return hasAnyRole(user, it.roles);
  };

  const getItemsToShow = (itemsList: NavItemModel[] | undefined) => itemsList?.filter(canShowNavItem)
    ?.map((it) => ({ ...it, children: getItemsToShow(it.children) }));
  return getItemsToShow(items);
};

const NavSection: FC<React.PropsWithChildren<NavSectionProps>> = (props) => {
  const {
    items,
    pathname,
    title,
    ...other
  } = props;

  return (
    <List
      subheader={(
        <ListSubheader
          disableGutters
          disableSticky
          sx={{
            color: 'text.primary',
            fontSize: '0.75rem',
            lineHeight: 2.5,
            fontWeight: 700,
            textTransform: 'uppercase'
          }}
        >
          {title}
        </ListSubheader>
            )}
      {...other}
    >
      {renderNavItems({
        items,
        pathname
      })}
    </List>
  );
};

NavSection.propTypes = {
  items: PropTypes.array,
  pathname: PropTypes.string,
  title: PropTypes.string
};

export default NavSection;
